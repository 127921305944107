<template>
  <div class="giftexchange_big">
    <div class="bread-line">
      <div class="safe-center">
        礼品专区<span>·</span>礼品兑换
      </div>
    </div>
    <!-- 主题区域 -->
    <div class="safe-center" style="">
      <div class="exchange_top">
        <h3 id="lpdh">礼品兑换</h3>
        <div class="gift-box">
          <div class="img_box">
            <img :src="giftinfo.img" alt="">
          </div>
          <div class="font-box">
            <h4>{{ giftinfo.gift_name }}</h4>
            <div>颜色：{{ checkcolor }}</div>
            <p>
              <img src="@/assets/image/doble-h.png" alt="">
              {{ giftinfo.quantity * num }}
            </p>
          </div>
          <div class="num_count">
            <el-button
              type="text"
              style="float:left;"
              disabled
              @click="reduce"
            >-</el-button>
            <el-input
              v-model="num"
              type="text"
              style="float:left;width:35px;"
              disabled
            ></el-input>
            <el-button
              type="text"
              style="float:left;"
              disabled
              @click="add"
            >+</el-button>
          </div>
        </div>
      </div>
      <!-- 收货地址 -->
      <div class="address_box">
        <h4>
          <span>收货人信息</span>
          <el-button
            type="button"
            style="float:right;border:1px solid #ee2e2e;color:#ee2e2e;border-radius:10px;padding:8px 15px;"
            @click="more_address"
          >新增收货地址</el-button>
        </h4>
        <ul class="address-list">
          <li
            v-for="(item, index) in addresslist"
            :key="index"
            :class="moadd"
            @click="chooseaddress(index)"
          >
            <span :class="index === addressindex ? 'defalut_address' : ''">{{
              item.receiver
            }}</span>
            <i>{{ item.mobile }}</i>
            <i>{{ item.address_json.province }}{{ item.address_json.city
            }}{{ item.address_json.county }}{{ item.address_json.town
            }}{{ item.detail_address }}</i>
            <em v-if="item.is_default === 1" id="ems">默认地址</em>
            <div style="float:right;">
              <el-button
                v-if="item.is_default !== 1"
                type="text"
                @click="mraddressM(item.id)"
              >设为默认</el-button>
              <el-button type="text" @click="editAdress(item)">编辑</el-button>
              <el-button
                type="text"
                @click="deladdressM(item.id)"
              >删除</el-button>
            </div>
          </li>
        </ul>
        <div
          v-if="!showopen && openflag"
          class="sq_btn"
          @click="closeaddress"
        ></div>
        <div
          v-if="showopen && openflag"
          class="open_btn"
          @click="openaddress"
        ></div>
      </div>
      <!-- 商品信息 -->
      <div v-if="giftinfo.gift_source===1" class="product_info">
        <h4>
          <span>商品信息</span>
        </h4>
        <ul class="product_main">
          <li>
            <span>颜色</span>
            <el-radio-group
              v-model="checkcolor"
              class="colorgroup"
              @change="hqgeM"
            >
              <el-radio-button
                v-for="(item, index) in colorlist"
                :key="index"
                :label="item"
              ></el-radio-button>
            </el-radio-group>
          </li>
          <li>
            <!-- gelist,checkge -->
            <span>规格</span>
            <el-radio-group v-model="checkge" class="colorgroup">
              <el-radio-button
                v-for="(item, index) in gelist"
                :key="index"
                :label="item.specs"
              ></el-radio-button>
            </el-radio-group>
          </li>
        </ul>
      </div>
      <!-- 结算 -->
      <div class="pay_box">
        <span>共<em>{{ num }}</em>件产品</span>
        <span>金额：<img src="@/assets/image/doble-h.png" alt=""><i>
          {{ giftinfo.quantity * num }}</i></span>
      </div>
      <div class="pay_btn">
        <el-button type="primary" @click="payMethos">立即支付</el-button>
      </div>
    </div>
    <!-- 新增收货地址 -->
    <div v-show="showModel" class="more_messbox">
      <div class="more-center">
        <img src="@/assets/image/white_close.png" alt="" @click="closeBtn">
        <p>{{ addressTitle }}</p>
        <el-form
          ref="addressForm"
          :model="addressForm"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="收货人" prop="name">
            <el-input v-model="addressForm.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input
              v-model="addressForm.phone"
              placeholder="请输入11位的手机号码"
              type="tel"
              maxlength="11"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="所在区域" prop="addresstring">
            <el-input
              ref="areafocus"
              v-model="addressForm.addresstring"
              clearable
              @focus="getCity()"
            ></el-input>
            <!-- <input
              v-model="addressForm.address"
              readonly="readonly "
              type="text"
              @focus="getProvinceM()"
            /> -->
          </el-form-item>
          <el-form-item label="详细地址" prop="addressInfo">
            <el-input v-model="addressForm.addressInfo" clearable></el-input>
          </el-form-item>
        </el-form>
        <div class="save_address">
          <el-button
            type="primary"
            @click="saveAddress('addressForm')"
          >保存新地址</el-button>
        </div>
      </div>
      <!-- 城市表单 -->
      <div v-show="showCity" class="city">
        <p class="title">所在地区<span @click="backUp()">[返回上一级]</span></p>
        <img
          src="https://newoss.zhulong.com/cdn/bbs/img/indeximg/close.gif"
          alt=""
          @click="closeFun()"
        >
        <div>
          <span
            v-for="(val, key) in district"
            v-show="!childrenShow"
            :key="val"
            @click="writeCity(district[key], key)"
          >{{ key }}</span>
          <span
            v-for="(val, key) in children"
            v-show="childrenShow && !childrenShow2"
            :key="val"
            @click="writeCity2(children[key], key)"
          >{{ key }}</span>
          <span
            v-for="(val, key) in children2"
            v-show="childrenShow2 && !childrenShow3"
            :key="val"
            @click="writeCity3(children2[key], key)"
          >{{ key }}</span>
          <span
            v-for="(val, key) in children3"
            v-show="childrenShow3"
            :key="val"
            @click="writeCity4(children3[key], key)"
          >{{ key }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Cookies from "js-cookie";
import { giftinfo } from '@/api/giftinfo.js'
import {
  getPCCList,
  getProvince,
  getCity,
  getCounty,
  getTown,
  editAddress,
  addAddress,
  addressList,
  getUserAddressList,
  deladdress,
  gelist,
  mraddress,
  exchage,
  getOneAddressInfo,
  updateAddress
} from '@/api/giftinfo.js'
export default {
  data() {
    return {
      giftinfo: { gift_source: 2 },
      colorlist: [],
      gelist: [],
      num: 3,
      checkcolor: '',
      checkge: '',
      moadd: '',
      addressTitle: '新增收货地址',
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        showCity: false
      },
      showModel: false,
      rules: {
        name: [
          { required: true, message: '请输入收货人信息', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          {
            validator: function(rule, value, callback) {
              if (
                /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/.test(
                  value
                ) === false
              ) {
                callback(new Error('手机号格式错误'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        addresstring: [
          { required: true, message: '请填写地址', trigger: 'blur' }
        ],
        addressInfo: [
          { required: true, message: '请填写详细地址', trigger: 'blur' }
        ]
      },
      // 城市列表
      showCity: false,
      district: [],
      children: [],
      children2: [],
      children3: [],
      childrenShow: false,
      childrenShow2: false,
      childrenShow3: false,
      addressForm: {
        name: '',
        phone: '',
        address: [],
        addressId: [],
        addressInfo: '',
        addresstring: ''
      },
      address: '',
      addresslist: [],
      openflag: false,
      itemAddressid: '',
      showopen: false,
      addressindex: 0,
      sel_addr_step: 0
    }
  },
  mounted() {
    var banner = document.getElementById('banner')
    banner.style.display = 'none'
    this.getgiftinfo()
    // gift_source值为2表示京东
    // console.log(this.giftinfo.gift_source, 'this.giftinfo.gift_source')
  },
  methods: {
    getgiftinfo() {
      const info = {
        id: this.$route.query.giftid
      }
      this.num = this.$route.query.num
      giftinfo(info)
        .then((res) => {
          this.giftinfo = res.result
          this.colorlist = res.result.specs
          // this.checkcolor = res.result.specs[0];
          // if (res.result.specs > 0) {
          //   this.hqgeM();
          // }
        }).then(() => {
          if (this.giftinfo.gift_source === 2) {
            // 京东
            this.getProvinceM()
            this.getaddress()
          } else {
            this.getProvinceList()
            this.getaddressList()
          }
          // console.log(this.giftinfo.gift_source, 'this.giftinfo.gift_source')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取规格列表
    hqgeM() {
      const info = {
        gift_id: this.$route.query.giftid,
        color: this.checkcolor
      }
      gelist(info)
        .then((res) => {
          this.gelist = res.result
          // this.checkge = this.gelist[0].specs;
        })
        .catch((err) => {
          console.log(err)
        })
    },
    reduce() {
      if (this.num === 0) {
        return
      } else {
        this.num--
      }
    },
    add() {
      this.num++
    },
    // 新增收货地址
    more_address() {
      // console.log(111111111)
      this.$refs['addressForm'].resetFields()
      this.childrenShow = false
      this.childrenShow2 = false
      this.childrenShow3 = false
      this.showCity = false
      this.itemAddressid = ''
      this.addressTitle = '新增收货地址'
      this.showModel = true
      this.addressForm.addressId[0] = ''
      this.addressForm.addressId[1] = ''
      this.addressForm.addressId[2] = ''
      this.addressForm.addressId[3] = ''
      this.addressForm.address[0] = ''
      this.addressForm.address[1] = ''
      this.addressForm.address[2] = ''
      this.addressForm.address[3] = ''
      this.addressForm.addressInfo = ''
      this.addressForm.phone = ''
      this.addressForm.name = ''
      this.addressForm.addresstring =
        this.addressForm.address[0].toString() +
        this.addressForm.address[1].toString() +
        this.addressForm.address[2].toString() +
        this.addressForm.address[3].toString()
    },
    // 编辑收货地址
    editAdress(row) {
      console.log(row, '编辑收货地址')
      this.childrenShow = false
      this.childrenShow2 = false
      this.childrenShow3 = false
      this.showCity = false
      this.$refs['addressForm'].resetFields()
      this.itemAddressid = row.id
      this.showModel = true
      this.addressTitle = '修改收货地址'
      this.addressForm.addressId[0] = row.province_id
      this.addressForm.addressId[1] = row.city_id
      this.addressForm.addressId[2] = row.county_id
      this.addressForm.address[0] = row.address_json.province
      this.addressForm.address[1] = row.address_json.city
      this.addressForm.address[2] = row.address_json.county
      this.addressForm.address[3] = row.address_json.town
      this.addressForm.addressInfo = row.detail_address
      if (this.giftinfo.gift_source === 2) {
        this.addressForm.phone = Number(row.mobile)
      } else {
        getOneAddressInfo({ id: row.id }).then((res) => {
          // console.log(res, ';;;;;;;;')
          this.addressForm.phone = res.result.mobile
        })
      }
      this.addressForm.name = row.receiver
      this.addressForm.addresstring =
        this.addressForm.address[0].toString() +
        this.addressForm.address[1].toString() +
        this.addressForm.address[2].toString() +
        this.addressForm.address[3].toString()
      if (this.giftinfo.gift_source === 2) {
        this.getaddress()
      } else {
        this.getaddressList()
      }
    },
    // 获取省市县列表 原创
    getProvinceList() {
      getPCCList()
        .then((res) => {
          // console.log(res, '原创列表')
          var province_obj = {}
          for (var i = 0; i < res.result.length; i++) {
            // console.log(res.result[i].id)
            province_obj[res.result[i].cname] = res.result[i].id
          }
          this.district = province_obj
          // console.log(province_obj, 'province_objprovince_objprovince_obj')
          // this.district = res.result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取省列表 京东
    getProvinceM() {
      getProvince()
        .then((res) => {
          this.district = res.result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 点击省获取市列表
    writeCity(val, key) {
      this.sel_addr_step = 1
      // 省的id
      this.addressForm.address[0] = key
      this.addressForm.addressId[0] = val
      this.addressForm.addresstring = this.addressForm.address[0].toString()
      this.childrenShow = true
      this.$refs.areafocus.focus()
      // 获取市列表
      if (this.giftinfo.gift_source === 2) {
        getCity({ id: val })
          .then((res) => {
            this.children = res.result
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        getPCCList({ parent_id: val })
          .then((res) => {
            var obj = {}
            for (var i = 0; i < res.result.length; i++) {
            // console.log(res.result[i].id)
              obj[res.result[i].cname] = res.result[i].id
            }
            this.children = obj
            // this.children = res.result
          })
          .catch((err) => {
            console.log(err)
          })
      }

      return
    },
    // 点击市，获取区县信息列表
    writeCity2(val, key) {
      this.childrenShow2 = true
      this.sel_addr_step = 2
      // 市的id
      this.addressForm.address[1] = key
      this.addressForm.addresstring =
        this.addressForm.address[0].toString() +
        this.addressForm.address[1].toString()
      this.addressForm.addressId[1] = val
      if (this.giftinfo.gift_source === 2) {
        getCounty({ id: this.addressForm.addressId[1] })
          .then((res) => {
            this.children2 = res.result
            this.addressForm.address[2] = key
            this.addressForm.addressId[2] = val
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        getPCCList({ parent_id: this.addressForm.addressId[1] })
          .then((res) => {
            var obj = {}
            for (var i = 0; i < res.result.length; i++) {
              obj[res.result[i].cname] = res.result[i].id
            }
            this.children2 = obj
            this.addressForm.address[2] = key
            this.addressForm.addressId[2] = val
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    writeCity3(val, key) {
      this.childrenShow3 = true
      this.sel_addr_step = 3
      this.addressForm.address[2] = key
      this.addressForm.addresstring =
        this.addressForm.address[0].toString() +
        this.addressForm.address[1].toString() +
        this.addressForm.address[2].toString()
      this.addressForm.addressId[2] = val
      if (this.giftinfo.gift_source === 2) {
        // 京东
        getTown({ id: this.addressForm.addressId[2] })
          .then((res) => {
            this.children3 = res.result
            if (res.result.length === 0) {
              this.addressForm.address[3] = ''
              this.addressForm.addressId[3] = 0
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        // 原创
        getPCCList({ parent_id: this.addressForm.addressId[2] })
          .then((res) => {
            var obj = {}
            for (var i = 0; i < res.result.length; i++) {
              obj[res.result[i].cname] = res.result[i].id
            }
            this.children3 = obj
            if (res.result.length === 0) {
              this.addressForm.address[3] = ''
              this.addressForm.addressId[3] = 0
            }
            // 关闭
            this.showCity = false
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 获取街道
    writeCity4(val, key) {
      console.log(val, 'city4')
      this.sel_addr_step = 4
      this.addressForm.address[3] = key
      this.addressForm.addresstring =
        this.addressForm.address[0].toString() +
        this.addressForm.address[1].toString() +
        this.addressForm.address[2].toString() +
        this.addressForm.address[3].toString()
      this.addressForm.addressId[3] = val
      // 关闭
      this.showCity = false
    },
    getCity() {
      this.showCity = true
    },
    // 关闭选择城市
    closeFun() {
      this.showCity = false
      console.log(this.sel_addr_step, 'sel_addr_step')

      // 清空
      if (this.giftinfo.gift_source === 2) {
        // 京东
        if (this.sel_addr_step < 4) {
          this.clearAddressFun()
        }
      } else {
        // 原创
        if (this.sel_addr_step < 2) {
          this.clearAddressFun()
        }
      }
    },
    clearAddressFun() {
      // 关闭清空行为
      this.childrenShow = false
      this.childrenShow2 = false
      this.childrenShow3 = false

      this.addressForm.addressId[0] = ''
      this.addressForm.addressId[1] = ''
      this.addressForm.addressId[2] = ''
      this.addressForm.addressId[3] = ''
      this.addressForm.address[0] = ''
      this.addressForm.address[1] = ''
      this.addressForm.address[2] = ''
      this.addressForm.address[3] = ''
      this.addressForm.addressInfo = ''
      this.addressForm.addresstring = ''
    },
    // 返回上一级
    backUp() {
      if (this.childrenShow3 === true) {
        this.childrenShow3 = false
        if (this.giftinfo.gift_source === 2) {
          this.sel_addr_step = 3
        } else {
          this.sel_addr_step = 2
        }
      } else if (this.childrenShow2 === true) {
        this.childrenShow2 = false
        if (this.giftinfo.gift_source === 2) {
          this.sel_addr_step = 2
        } else {
          this.sel_addr_step = 1
        }
      } else {
        this.childrenShow = false
        if (this.giftinfo.gift_source === 2) {
          this.sel_addr_step = 1
        } else {
          this.sel_addr_step = 0
        }
      }
    },
    cancelCity() {
      this.showCity = false
      this.childrenShow = false
      this.childrenShow2 = false
      this.childrenShow3 = false
    },
    closeBtn() {
      this.showModel = false
    },
    // 保存新地址
    saveAddress(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          const info = {
            province_id: this.addressForm.addressId[0],
            city_id: this.addressForm.addressId[1],
            county_id: this.addressForm.addressId[2],
            town_id: this.addressForm.addressId[3],
            province: this.addressForm.address[0],
            city: this.addressForm.address[1],
            county: this.addressForm.address[2],
            town: this.addressForm.address[3],
            mobile: this.addressForm.phone,
            detail_address: this.addressForm.addressInfo,
            id: this.itemAddressid,
            receiver: this.addressForm.name
          }
          if (this.giftinfo.gift_source === 2) {
            addAddress(info)
              .then((res) => {
                if (res.result === 'OK' && this.itemAddressid === '') {
                  // this.$toast('添加成功')
                  this.$message.success('添加成功')
                } else if (res.result === 'OK' && this.itemAddressid !== '') {
                  // this.$toast('修改成功')
                  this.$message.success('修改成功')
                } else {
                  // this.$toast(res.msg)
                  this.$alert(res.msg, '提示', {})
                }
                this.showModel = false
                this.getaddress()
              })
              .then((err) => {
                console.log(err)
              })
          } else {
            editAddress(info)
              .then((res) => {
                if (res.result === 'OK' && this.itemAddressid === '') {
                  // this.$toast('添加成功')
                  this.$message.success('添加成功')
                } else if (res.result === 'OK' && this.itemAddressid !== '') {
                  // this.$toast('修改成功')
                  this.$message.success('修改成功')
                } else {
                  // this.$toast(res.result)
                  this.$alert(res.result, '提示', {})
                }
                this.showModel = false
                this.getaddressList()
              })
              .then((err) => {
                console.log(err)
              })
          }
        } else {
          return false
        }
      })
    },
    // 收货地址列表  京东
    getaddress() {
      addressList()
        .then((res) => {
          this.addresslist = res.result.list
          if (this.addresslist.length > 2) {
            this.openflag = true
          }
          for (var i = 0; i < res.result.list.length; i++) {
            if (res.result.list[i].is_default === 1) {
              this.addressindex = i
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 原创
    getaddressList() {
      getUserAddressList()
        .then((res) => {
          for (var i = 0; i < res.result.list.length; i++) {
            res.result.list[i]['address_json'] = {
              city: res.result.list[i].city,
              county: res.result.list[i].county,
              province: res.result.list[i].province,
              town: ''
            }
          }
          this.addresslist = res.result.list
          if (this.addresslist.length > 2) {
            this.openflag = true
          }
          for (var j = 0; j < res.result.list.length; j++) {
            if (res.result.list[j].is_default === 1) {
              this.addressindex = j
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 删除收获地址
    deladdressM(rowid) {
      if (this.giftinfo.gift_source === 2) {
        deladdress({ id: rowid })
          .then((res) => {
            if (res.result === 'OK') {
              // this.$toast('删除成功')
              this.$message.success('删除成功')
            }
            this.getaddress()
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        var info = {
          id: rowid,
          status: 0
        }
        updateAddress(info).then((res) => {
          if (res.result === 'OK') {
            // this.$toast('删除成功')
            this.$message.success('删除成功')
          }
          this.getaddressList()
        })
      }
    },
    // 设置默认地址
    mraddressM(val) {
      if (this.giftinfo.gift_source === 2) {
        mraddress({ id: val })
          .then((res) => {
            this.getaddress()
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        var info = {
          id: val,
          is_default: 1
        }
        updateAddress(info).then((res) => {
          this.getaddressList()
        })
      }
    },
    // 收起地址
    closeaddress() {
      var addressbox = document.getElementsByClassName('address-list')[0]
      addressbox.style.display = 'none'
      this.showopen = true
    },
    // 展开地址
    openaddress() {
      var addressbox = document.getElementsByClassName('address-list')[0]
      addressbox.style.display = 'block'
      this.showopen = false
    },
    // 立即支付
    payMethos() {
      var info
      // 地址
      // var address = this.addresslist.find(
      //   (item) => item.is_default === this.addressindex
      // )
      if (this.addresslist.length === 0) {
        return this.$alert('请选择邮寄地址', '提示', {})
      }
      if (this.giftinfo.gift_source === 1) {
        info = {
          gift_source: this.giftinfo.gift_source,
          id: this.$route.query.giftid,
          address_id: this.addresslist[this.addressindex].id,
          num: this.num,
          remark: '',
          specs_id: ''
        }
        // 规格id获取
        if (this.checkge !== '') {
          var checkge = this.gelist.find((item) => item.specs === this.checkge)
          info.specs_id = checkge.id
        } else {
          // this.$toast('请选择商品信息！')
          this.$alert('请选择商品信息！', '提示', {})
          return
        }
      } else {
        info = {
          gift_source: this.giftinfo.gift_source,
          id: this.$route.query.giftid,
          address_id: this.addresslist[this.addressindex].id,
          num: this.num,
          remark: ''
        }
      }
      exchage(info)
        .then((res) => {
          if (res.errNo !== 0) {
            this.$alert(res.msg, '提示', {})
            // this.$router.push({
            //   path: 'paysuccess',
            //   query: { gift_source: this.giftinfo.gift_source }
            // })
          } else {
            this.$router.push({
              path: 'paysuccess',
              query: { gift_source: this.giftinfo.gift_source }
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 选择地址
    chooseaddress(val) {
      this.addressindex = val
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.main-box{
  background-color: #f2f2f2 !important;
}
.img_box{
  width: 140px;
  height: 140px;
}
#lpdh{
  padding-top: 1px;
}
#ems{
  padding-bottom: 23px;
}
.bread-line {
  background: rgba(0, 0, 0, 0.5);
  height: 45px;
  line-height: 45px;
  color: white;
  text-align: left;
  margin-bottom: 15px;
  span {
    padding: 0 10px;
  }
}
.safe-center {
  width: 1200px;
  margin: 0 auto;
  margin-top: 15px;
  background-color: #fff;

}
.giftexchange_big {
  // background: white;
}
.defalut_address {
  background: url("../../assets/image/defalutbg.png") no-repeat;
  background-size: 100% 100%;
}
.exchange_top {
  height: 270px;
  padding: 0 150px;
  background: url("../../assets/image/exbg.png") no-repeat;
  h3 {
    height: 86px;
    line-height: 86px;
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
  .gift-box {
    width: 100%;
    height: 180px;
    background: #f7f7f7;
    border-radius: 5px;
    padding: 20px;
    overflow: hidden;
    > div {
      height: 100%;
      float: left;
      margin-right: 30px;
      > img {
        width: 140px;
        height: 140px;
      }
    }
    .font-box {
      width: 500px;
      text-align: left;
      padding: 5px 0px;
      h4 {
        font-size: 18px;
        color: #333;
        height: 45px;
        line-height: 45px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
      }
      > div {
        color: #666;
        font-size: 14px;
        line-height: 35px;
        height: 35px;
      }
      p {
        color: #ee2d2e;
        height: 26px;
        line-height: 26px;
        font-size: 16px;
        margin: 10px 0px;
        img {
          height: 20px;
          margin-top: -4px;
          width: 20px;
        }
      }
    }
  }
  /deep/.el-input__inner {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    padding: 0px;
    border-radius: 0px;
    border-left: 0px;
    border-right: 0px;
    font-size: 18px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 0px;
    float: left;
    font-size: 14px;
  }
  /deep/.el-button--text {
    width: 35px;
    height: 35px;
    color: #333;
    background: white;
    border-radius: 0px;
    border: 1px solid #ddd;
    float: left;
    padding: 0px;
  }
}

.num_count {
  padding-top: 90px;
}
// 收货地址
.product_info,
.address_box {
  padding: 20px 0 0 0;
  width: 898px;
  margin: 0 auto;
  position: relative;
  h4 {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    height: 32px;
    line-height: 32px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    box-sizing: content-box;
    span {
      float: left;
      margin-left: 10px;
    }
  }
}
.address_box {
  margin-bottom: 35px;
  .sq_btn,
  .open_btn {
    width: 123px;
    height: 30px;
    position: absolute;
    bottom: -30px;
    left: 50%;
    margin-left: -60px;
    background: url("../../assets/image/close_address.png") no-repeat;
    background-size: 100% 100%;
    color: white;
    font-size: 14px;
    cursor: pointer;
  }
  .open_btn {
    background: url("../../assets/image/pen_address.png") no-repeat;
  }
}
.address_box h4::before,
.product_info h4::before {
  content: "";
  display: block;
  width: 5px;
  height: 20px;
  background: #ee2e2e;
  float: left;
  margin: 6px 0px;
}
.address-list {
  padding: 20px 0px;
  margin: 0px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  li {
    margin: 0px;
    padding: 0px;
    list-style: none;
    line-height: 30px;
    overflow: hidden;
    margin-bottom: 15px;
    span {
      width: 140px;
      height: 30px;
      border: 1px solid #ddd;
      float: left;
      margin-right: 20px;
    }
    i {
      font-style: normal;
      margin-right: 10px;
      float: left;
    }
    em {
      background: #ee2e2e;
      color: white;
      padding: 2px 9px;
      font-style: normal;
      float: left;
      height: 20px;
      margin: 5px 0px;
      line-height: 20px;
    }
    /deep/.el-button--text {
      border: 0px;
      color: #ee2e2e;
    }
  }
}
.address-list li:last-child {
  margin-bottom: 0px;
}
.mo_address {
  span {
    border: 2px solid #ee2e2e !important;
  }
}
.mo_address::after {
  content: "默认地址";
  display: block;
  // width: 5px;
  height: 20px;
  line-height: 20px;
  margin: 5 0px;
  padding: 0px 5px;
  background: #ee2e2e;
  float: left;
  margin: 6px 0px;
  color: white;
}
.product_main {
  text-align: left;
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
  li {
    height: 30px;
    line-height: 30px;
    margin-top: 20px;
    text-align: left;
    font-size: 14px;
    span {
      float: left;
      margin-right: 15px;
    }
    /deep/.el-radio-button__inner {
      margin-right: 15px;
    }
    /deep/.el-radio-button:last-child .el-radio-button__inner,
    /deep/.el-radio-button:first-child .el-radio-button__inner {
      border-radius: 0px;
    }
    /deep/.el-radio-button__inner {
      border: 1px solid #dcdfe6;
      padding: 0px 10px;
      height: 30px;
      line-height: 30px;
      // width: 60px;
    }
    /deep/.is-active .el-radio-button__inner {
      border: 0px;
    }
  }
}
.colorgroup {
  float: left;
  height: 100%;
  width: 300px;
}
.pay_box {
  border-bottom: 1px solid #ddd;
  width: 898px;
  margin: 0 auto;
  text-align: right;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding: 15px 0px;
  box-sizing: content-box;
  span {
    margin-right: 15px;
    img {
      height: 20px;
      width: 20px;
      vertical-align: middle;
    }
  }
  em {
    padding: 0 3px;
  }
  em,
  i {
    font-style: normal;
    color: #ee2e2e;
  }
  i {
    font-weight: bold;
    padding: 0 3px;
  }
}
.pay_btn {
  width: 898px;
  margin: 0 auto;
  text-align: right;
  padding: 20px 0px 40px 0px;
}
// 新增收货地址
.more_messbox {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0px;
  .more-center {
    width: 500px;
    background: white;
    position: absolute;
    left: 50%;
    top: 20%;
    margin-left: -250px;
    padding: 0 35px;
    img {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
    p {
      font-size: 18px;
      line-height: 70px;
      height: 70px;
      // font-weight: bold;
      color: #333;
    }
  }
}
.save_address {
  padding: 10px 0px 40px 0px;
}
.city {
  width: 420px;
  min-height: 320px;
  background: #fff;
  z-index: 102;
  box-shadow: 0px 0px 8px #999;
  position: absolute;
  top: 65%;
  left: 55%;
  transform: translate(-50%, -50%);
  img {
    position: absolute;
    right: 0;
    top: 0;
  }
  .title {
    text-align: center;
    margin-top: 15px;
    color: #000;
    span {
      color: #ee2e2e;
      cursor: pointer;
    }
  }
  div {
    width: 380px;
    height: auto;
    margin: 20px 0 10px 35px;
    display: flex;
    flex-wrap: wrap;
    span {
      display: block;
      margin: 10px 1px;
      min-width: 60px;
      height: 20px;
      cursor: pointer;
    }
  }
}
</style>
